import { Injectable } from "@angular/core";
import { AppState, AppStateStore } from "./app-state.store";

export interface AppStateAction {
  type: string;
  payload: any;
}

@Injectable()
export class AppStateReducers {

  constructor(
    private appStateStore: AppStateStore
  ) { }

  private emitNewState(newState: AppState) {
    this.appStateStore.appStateStore$.next(newState);
  }

  private refresh() {
    const newState: AppState = Object.assign(this.appStateStore.appStates, {});
    this.emitNewState(newState);
  }

  private setResourses(newResourses: string[]) {
    const newState: AppState = Object.assign(this.appStateStore.appStates, {
      resourses: newResourses
    });
    this.emitNewState(newState);
  }

  appStateReducer(action: AppStateAction) {
    switch (action.type) {
      case "REFRESH":
        this.refresh();
        return;
      case "SET_RESOURSES":
        this.setResourses(action.payload);
        return;
      default:
        return;
    }
  }

  clearAppState() {
    const clearState: AppState = Object.assign(
      this.appStateStore.appStates,
      this.appStateStore.generateBlankAppState()
    );
    this.emitNewState(clearState);
  }
}
