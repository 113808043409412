import { NgModule, ModuleWithProviders } from "@angular/core";
import { CommonModule } from "@angular/common";

import { AppStateStore } from "./app-state.store";
import { AppStateReducers } from "./app-state.actions";

const SERVICES = [
  AppStateStore,
  AppStateReducers
];

@NgModule({
  imports: [
    CommonModule
  ],
  providers: [
    ...SERVICES
  ]
})
export class AppStateModule {
  static forRoot(): ModuleWithProviders<AppStateModule> {
    return {
      ngModule: AppStateModule,
      providers: [
        ...SERVICES
      ]
    };
  };
}
