import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "./auth-guard.service";
import { LoginComponent } from "./login/login.component";

const routes: Routes = [
  { path: "", redirectTo: "pages", pathMatch: "full" },
  { path: "login", data: { title: "Login" }, component: LoginComponent },
  {
    path: "pages",
    canLoad: [AuthGuard],
    loadChildren: () => import("../app/pages/pages.module").then(m => m.PagesModule)
  },
  { path: "**", redirectTo: "pages" }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
