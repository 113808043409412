import { Injectable, OnDestroy } from "@angular/core";
import { ActivatedRoute, Data, NavigationEnd, Router } from "@angular/router";
import { ReplaySubject, Subject } from "rxjs";
import { filter, switchMap, takeUntil } from "rxjs/operators";

@Injectable({
  providedIn: "root"
})
export class RouteDataService implements OnDestroy {

  unsubscribe: Subject<boolean> = new Subject<boolean>();
  dataSubject: ReplaySubject<Data> = new ReplaySubject<Data>();

  constructor(
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        switchMap((_) => this.getChild(this.route).data),
        takeUntil(this.unsubscribe)
      ).subscribe((data: Data) => {
        this.dataSubject.next(data);
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(true);
    this.unsubscribe.complete();
  }

  private getChild(activatedRoute: ActivatedRoute): ActivatedRoute {
    if (activatedRoute.firstChild) {
      return this.getChild(activatedRoute.firstChild);
    } else {
      return activatedRoute;
    }
  }
}
