import { NgModule, ModuleWithProviders } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RestClient } from "./rest.client";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { HttpErrorHandlerInterceptor } from "./interceptor";
import { SpinnerService } from "./spinner.service";
import { SpinnerComponent } from "./spinner.component";
import { MatProgressBarModule } from "@angular/material/progress-bar";

const SERVICES = [
  RestClient,
  {
    provide: HTTP_INTERCEPTORS,
    useClass: HttpErrorHandlerInterceptor,
    multi: true
  },
  SpinnerService
];

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    MatProgressBarModule
  ],
  providers: [
    ...SERVICES
  ],
  declarations: [
    SpinnerComponent
  ]
})
export class RestModule {
  static forRoot(): ModuleWithProviders<RestModule> {
    return {
      ngModule: RestModule,
      providers: [
        ...SERVICES
      ]
    };
  }
}
