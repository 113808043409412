import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";

@Injectable()
export class ToasterService {

  constructor(
    private snackBar: MatSnackBar
  ) { }

  public open(
    message: string,
    action: string = "X",
    timerInSeconds: number = 5
  ) {
    this.snackBar.open(
      message,
      action,
      {
        horizontalPosition: "center",
        verticalPosition: "top",
        duration: timerInSeconds * 1000
      }
    );
  }
}
