import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";
import { Resourses } from "../data/models";


export interface AppState {
  resourses: Resourses | null;
}

@Injectable()
export class AppStateStore {

  public appStates: AppState;
  public appStateStore$: Subject<AppState>;

  constructor() {
    this.appStates = this.generateBlankAppState();
    this.appStateStore$ = new BehaviorSubject(this.appStates);
  }

  public generateBlankAppState(): AppState {
    return {
      resourses: null
    };
  }

  public getProp(propName: keyof AppState): any {
    if (propName in this.appStates) {
      return this.appStates[propName];
    }
    return null;
  }
}
