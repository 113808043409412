import { ModuleWithProviders, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AuthService } from "./auth.service";

const SERVICES = [
  AuthService
];

@NgModule({
  imports: [
    CommonModule
  ],
  providers: [
    ...SERVICES
  ]
})
export class AuthModule {
  static forRoot(): ModuleWithProviders<AuthModule> {
    return {
      ngModule: AuthModule,
      providers: [
        ...SERVICES
      ]
    };
  };
}
