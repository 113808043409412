import { Injectable, inject } from "@angular/core";
import { Router } from "@angular/router";
import { tap } from "rxjs/operators";
import { AuthService } from "./@core/auth/auth.service";

@Injectable()
export class AuthGuard  {

  private authService = inject(AuthService);
  private router = inject(Router);

  canLoad() {
    return this.authService.isAuthenticated()
      .pipe(
        tap(authenticated => {
          if (!authenticated) {
            this.router.navigate(["/login"]);
          }
        })
      );
  }
}
