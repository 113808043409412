import { ModuleWithProviders, NgModule, Optional, SkipSelf } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AppStateModule } from "./app-state/app-state.module";
import { throwIfAlreadyLoaded } from "./module-import-guard";
import { AuthModule } from "./auth/auth.module";
import { RestModule } from "./rest/rest.module";
import { ToasterModule } from "./toaster/toaster.module";
import { RouteDataModule } from "./routeData/routeData.module";

const CORE_PROVIDERS = [
  ...(AppStateModule.forRoot().providers || []),
  ...(AuthModule.forRoot().providers || []),
  ...(RestModule.forRoot().providers || []),
  ...(ToasterModule.forRoot().providers || []),
  ...(RouteDataModule.forRoot().providers || [])
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  exports: []
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, "CoreModule");
  }

  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        ...CORE_PROVIDERS
      ]
    };
  }
}
