<div class="container">
  <mat-card class="card">
    <mat-card-header>
      <mat-card-title>
        <div class="mat-headline color-primary">
          Введіть логін та пароль
        </div>
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <form [formGroup]="loginForm">
        <div class="form-field">
          <mat-icon>person</mat-icon>
          <mat-form-field style="width:90%">
            <input matInput aria-label="User"
              placeholder="Логін"
              formControlName="login"
            >
            <mat-error>
              <span *ngIf="login?.invalid && login?.touched">
                Обовʼязкове поле!
              </span>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="form-field">
          <mat-icon matPrefix>security</mat-icon>
          <mat-form-field style="width:90%">
            <input matInput aria-label="Password"
              type="password"
              placeholder="Пароль"
              formControlName="password"
            >
            <mat-hint>Мін 6 символа</mat-hint>
            <mat-error>
              <span *ngIf="password?.invalid && password?.touched">
                Має бути більше ніж 6 символів
              </span>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="form-field">
          <div *ngIf="errorMessage" class="error-message bg-color-warn">
            {{ errorMessage }}
          </div>
          <button mat-raised-button
            color="primary"
            type="submit"
            (click)="send()"
            [disabled]="loginForm.invalid"
          >
            Увійти
          </button>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</div>
