import { Component, OnDestroy, OnInit, inject } from "@angular/core";
import { NonNullableFormBuilder, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { AuthService } from "../@core/auth/auth.service";
import { Token } from "../@core/data/models";
import { RestClient } from "../@core/rest/rest.client";
import { ToasterService } from "../@core/toaster/toaster.service";

const MIN_LENGTH = 6;

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"]
})
export class LoginComponent implements OnInit, OnDestroy {

  errorMessage = "";

  private fb = inject(NonNullableFormBuilder);
  private router = inject(Router);
  private restClient = inject(RestClient);
  private authService = inject(AuthService);
  private toaster = inject(ToasterService);
  private unsubscribe = new Subject<void>();

  loginForm = this.fb.group({
    login: ["", Validators.required],
    password: ["", [Validators.required, Validators.minLength(MIN_LENGTH)]]
  });

  ngOnInit(): void {
    this.authService.isAuthenticated()
      .pipe(
        takeUntil(this.unsubscribe)
      ).subscribe(isAuthenticated => {
        if (isAuthenticated) {
          this.router.navigate(["/"]);
        }
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  send(): void {
    const value = this.loginForm.getRawValue();
    this.restClient.login(value).subscribe({
      next: (data: Token) => {
        this.toaster.open("Авторизація пройшла успішно!");
        localStorage.setItem("Token", data.jwtToken);
        this.router.navigate(["/"]);
      },
      error: (error) => {
        console.log("Login error", error);
        this.errorMessage = "Неправильний логін або пароль";
      }
    });
  }

  get login() {
    return this.loginForm.get("login");
  }

  get password() {
    return this.loginForm.get("password");
  }
}
