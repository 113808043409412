import { ModuleWithProviders, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ToasterService } from "./toaster.service";


const SERVICES = [
  ToasterService
];

@NgModule({
  imports: [
    CommonModule
  ],
  providers: [
    ...SERVICES
  ]
})
export class ToasterModule {
  static forRoot(): ModuleWithProviders<ToasterModule> {
    return {
      ngModule: ToasterModule,
      providers: [
        ...SERVICES
      ]
    };
  };
}
