import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Role, User } from "../data/models";
import { jwtDecode } from "jwt-decode";
import { PERMISSION, PERMISSIONS, ROLE } from "./permissions.config";

@Injectable()
export class AuthService {

  private _user: User;

  constructor() {
    this._user = {
      userName: "",
      fullName: "",
      expired: new Date(),
      roles: []
    };
  }

  getUser(): User {
    return this._user;
  }

  isAdminUser(): boolean {
    return this._user.roles.some((role: Role) => {
      return role.value.toUpperCase() === ROLE.ADMIN ||
             role.code.toUpperCase() === ROLE.ADMIN
    });
  }

  isAuthenticated(): Observable<boolean> {
    return new Observable(observer => {
      const token = localStorage.getItem("Token");
      if (!token) {
        observer.next(false);
        observer.complete();
        return;
      }
      this.setUserByToken(token);
      observer.next(this._user.expired > new Date());
      observer.complete();
    });
  }

  private setUserByToken(token: string) {
    try {
      const decodedToken: any = jwtDecode(token);
      this._user = {
        userName: decodedToken.sub,
        fullName: decodedToken.full_name,
        expired: new Date(decodedToken.exp * 1000),
        roles: decodedToken.auth
      }
    } catch(error) {
      console.warn("Token error", error);
      this.setUserLogedOut();
    }
  }

  setUserLogedOut() {
    localStorage.clear();
    this._user.expired = new Date();
    this._user.roles = [];
  }

  getPermissionByName(permissionName: string): boolean {
    const permissions: PERMISSION[] = this.getPermissions();
    return permissions.includes(permissionName as PERMISSION);
  }

  getPermissions(): PERMISSION[] {
    let permissions: PERMISSION[] = [];
    this._user.roles.forEach(({code, value}: Role) => {
      const key = <ROLE>value.toUpperCase();
      permissions = [...permissions, ...PERMISSIONS[key]];
    });
    return [...new Set(permissions)];
  }
}
