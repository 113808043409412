export enum ROLE {
  ADMIN = "ADMIN",
  USER = "USER"
};

export enum PERMISSION {
  VIEW_SEARCH_RESULTS = "VIEW_SEARCH_RESULTS",
  ADD_SEARCH_RESULTS = "ADD_SEARCH_RESULTS",
  EDIT_SEARCH_RESULTS = "EDIT_SEARCH_RESULTS",
  DELETE_SEARCH_RESULTS = "DELETE_SEARCH_RESULTS",
  VIEW_QUESTIONNAIRES = "VIEW_QUESTIONNAIRES",
  ADD_QUESTIONNAIRES = "ADD_QUESTIONNAIRES",
  EDIT_QUESTIONNAIRES = "EDIT_QUESTIONNAIRES",
  DELETE_QUESTIONNAIRES = "DELETE_QUESTIONNAIRES",
}
export const PERMISSIONS = {
  [ROLE.ADMIN]: [
    PERMISSION.VIEW_SEARCH_RESULTS,
    PERMISSION.ADD_SEARCH_RESULTS,
    PERMISSION.EDIT_SEARCH_RESULTS,
    PERMISSION.DELETE_SEARCH_RESULTS,
    PERMISSION.VIEW_QUESTIONNAIRES,
    PERMISSION.ADD_QUESTIONNAIRES,
    PERMISSION.EDIT_QUESTIONNAIRES,
    PERMISSION.DELETE_QUESTIONNAIRES
  ],
  [ROLE.USER]: [
    PERMISSION.VIEW_SEARCH_RESULTS,
    PERMISSION.ADD_SEARCH_RESULTS,
    PERMISSION.EDIT_SEARCH_RESULTS,
    PERMISSION.VIEW_QUESTIONNAIRES,
    PERMISSION.ADD_QUESTIONNAIRES,
    PERMISSION.EDIT_QUESTIONNAIRES
  ],
  NO_ROLE: [
    PERMISSION.VIEW_SEARCH_RESULTS,
    PERMISSION.VIEW_QUESTIONNAIRES
  ]
};
