import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { EMPTY, Observable, timer } from "rxjs";
import { Router } from "@angular/router";
import { Token, UserLoginData, SearchResultsListQueryParameters, SearchResultsListResponse,
         Resourses, SearchResult, QuestionnairesListQueryParameters, QuestionnairesListResponse,
         Questionnaire } from "../data/models";
import { environment as env }from "./../../../environments/environment";
import { AuthService } from "../auth/auth.service";
import { ToasterService } from "../toaster/toaster.service";
import { switchMap } from "rxjs/operators";
import { isMoment } from "moment";

type ResponseType = "arraybuffer" | "blob" | "json" | "text";
interface PlainObject { [key: string]: any };

interface HttpOptions {
  headers: HttpHeaders;
  responseType?: ResponseType;
  params?: HttpParams;
}

@Injectable()
export class RestClient {

  constructor(
    private toaster: ToasterService,
    private httpClient: HttpClient,
    private authService: AuthService,
    private router: Router
  ) { }

  private createEndpoint(url: string) {
    return env.api + url;
  }

  private createHttpOptions(
    params?: HttpParams,
    contentType?: string,
    responseType?: ResponseType,
    isFormData?: boolean
  ): HttpOptions {
    const headersObject: PlainObject = {
      "Accept": "application/json",
      "Access-Control-Allow-Origin": "true",
      "Content-Type": contentType || "application/json"
    };
    const token = localStorage.getItem("Token");
    if (token) {
      headersObject["Authorization"] = "Bearer " + token;
    }
    if (isFormData) {
      delete headersObject["Content-Type"];
    }
    const httpOptions: HttpOptions = {
      headers: new HttpHeaders(headersObject),
      responseType: responseType || "json"
    };
    if (params) {
      httpOptions.params = params;
    }
    return httpOptions;
  }

  private rejectAuthObservable<T>(): Observable<T> {
    this.toaster.open("Токен авторизації не дійсний. Авторизуйтесь...");
    timer(1000).subscribe(() => this.router.navigate(["login"]));
    return EMPTY;
  }

  login(data: UserLoginData): Observable<Token> {
    return this.httpClient.post<Token>(
      this.createEndpoint("user/login"),
      data,
      this.createHttpOptions() as PlainObject
    );
  }

  private delete<T>(path: string): Observable<T> {
    return this.authService.isAuthenticated()
      .pipe(
        switchMap((value: boolean) => {
          if (value) {
            return this.httpClient.delete<T>(
              this.createEndpoint(path),
              this.createHttpOptions() as PlainObject
            );
          } else {
            return this.rejectAuthObservable<T>();
          }
        })
      );
  }

  private get<T>(
    path: string,
    params?: HttpParams,
    contentType?: string,
    responseType?: ResponseType
  ): Observable<T> {
    return this.authService.isAuthenticated()
      .pipe(
        switchMap((value: boolean) => {
          if (value) {
            return this.httpClient.get<T>(
              this.createEndpoint(path),
              this.createHttpOptions(params, contentType, responseType) as PlainObject
            );
          } else {
            return this.rejectAuthObservable<T>();
          }
        })
      );
  }

  private post<T>(path: string, postBody: any, formData?: boolean): Observable<T> {
    return this.authService.isAuthenticated()
      .pipe(
        switchMap((value: boolean) => {
          if (value) {
            return this.httpClient.post<T>(
              this.createEndpoint(path),
              postBody,
              this.createHttpOptions(undefined, undefined, undefined, formData) as PlainObject
            );
          } else {
            return this.rejectAuthObservable<T>();
          }
        })
      );
  }

  private patch<T>(path: string, patchBody: any, formData?: boolean): Observable<T> {
    return this.authService.isAuthenticated()
      .pipe(
        switchMap((value: boolean) => {
          if (value) {
            return this.httpClient.patch<T>(
              this.createEndpoint(path),
              patchBody,
              this.createHttpOptions(undefined, undefined, undefined, formData) as PlainObject
            );
          } else {
            return this.rejectAuthObservable<T>();
          }
        })
      );
  }

  getResourses(): Observable<Resourses> {
    return this.get<Resourses>("resourses");
  }

  getSearchResultsList(query: SearchResultsListQueryParameters): Observable<SearchResultsListResponse> {
    let params = new HttpParams();
    Object.keys(query).forEach((key: string) => {
      let value = query[key as keyof SearchResultsListQueryParameters];
      if (value) {
        if (isMoment(value)) {
          value = value.toISOString();
        }
        params = params.set(key, value);
      }
    });
    return this.get<SearchResultsListResponse>("search-results", params);
  }

  getSearchResultsItem(id: string | number): Observable<SearchResult> {
    return this.get<SearchResult>(`search-results/${id}`);
  }

  addSearchResultsItem(body: FormData): Observable<any> {
    return this.post<any>("search-results", body, true);
  }

  getQuestionnairesList(query: QuestionnairesListQueryParameters): Observable<QuestionnairesListResponse> {
    let params = new HttpParams();
    Object.keys(query).forEach((key: string) => {
      let value = query[key as keyof QuestionnairesListQueryParameters];
      if (value) {
        if (isMoment(value)) {
          value = value.toISOString();
        }
        params = params.set(key, value);
      }
    });
    return this.get<QuestionnairesListResponse>("questionnaires", params);
  }

  getQuestionnairesItem(id: string | number): Observable<Questionnaire> {
    return this.get<Questionnaire>(`questionnaires/${id}`);
  }

  addOrEditQuestionnairesItem(body: FormData, method: "post" | "patch", id: string | null): Observable<any> {
    return this[method]<any>("questionnaires" + (id ? `/${id}` : ""), body, true);
  }
  
}
