import { Component, OnDestroy, OnInit, inject } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { Data } from "@angular/router";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { RouteDataService } from "./@core/routeData/routeData.service";

@Component({
  selector: "app-root",
  template: "<router-outlet></router-outlet>"
})
export class AppComponent implements OnInit, OnDestroy {

  private titleService = inject(Title);
  private routeData = inject(RouteDataService);
  private unsubscribe = new Subject<void>();

  ngOnInit(): void {
    this.routeData.dataSubject
      .pipe(
        takeUntil(this.unsubscribe)
      ).subscribe((data: Data) => {
        this.titleService.setTitle(data.title);
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
