import { Injectable } from "@angular/core";
import { MatDialog, MatDialogRef, MatDialogState } from "@angular/material/dialog";
import { SpinnerComponent } from "./spinner.component";

@Injectable()
export class SpinnerService {

  dialogRef!: MatDialogRef<SpinnerComponent>;

  constructor(
    public dialog: MatDialog
  ) { }

  start(text: string): void {
    if (this.dialogRef?.getState() !== MatDialogState.OPEN) {
      this.dialogRef = this.dialog.open(SpinnerComponent, {
        data: { text }
      });
    }
  }

  stop(): void {
    if (this.dialogRef?.getState() === MatDialogState.OPEN) {
      this.dialogRef?.close();
    }
  }
}
