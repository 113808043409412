import { ModuleWithProviders, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouteDataService } from "./routeData.service";

const SERVICES = [
  RouteDataService
];

@NgModule({
  imports: [
    CommonModule
  ],
  providers: [
    ...SERVICES
  ]
})
export class RouteDataModule {
  static forRoot(): ModuleWithProviders<RouteDataModule> {
    return {
      ngModule: RouteDataModule,
      providers: [
        ...SERVICES
      ]
    };
  };
}
