import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "app-spinner",
  template: `
    <div style="padding: 10px;">
      <div class="color-primary" style="width: 200px;">{{ message }}</div>
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
  `
})
export class SpinnerComponent implements OnInit {

  message = "";

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {[key: string]: string}
  ) { }

  ngOnInit(): void {
    this.message = this.data.text;
  }

}
