import { Injectable } from "@angular/core";
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from "@angular/common/http";
import { Observable, of, throwError } from "rxjs";
import { catchError, delay, finalize } from "rxjs/operators";
import { ToasterService } from "../toaster/toaster.service";
import { SpinnerService } from "./spinner.service";
import { environment } from "../../../environments/environment";
import login from "../../../fixtures/api/v1/user/login.json";
import searchResults from "../../../fixtures/api/v1/search-results.json";
import resourses from "../../../fixtures/api/v1/resourses.json";
import questionnaires from "../../../fixtures/api/v1/questionnaires.json";
import searchResult0 from "../../../fixtures/api/v1/search-results/test-id-0.json";
import searchResult1 from "../../../fixtures/api/v1/search-results/test-id-1.json";
import searchResult2 from "../../../fixtures/api/v1/search-results/test-id-2.json";
import questionnaires0 from "../../../fixtures/api/v1/questionnaires/test-id-0-0.json";
import questionnaires1 from "../../../fixtures/api/v1/questionnaires/test-id-0-1.json";
import questionnaires2 from "../../../fixtures/api/v1/questionnaires/test-id-0-2.json";


@Injectable()
export class HttpErrorHandlerInterceptor implements HttpInterceptor {

  constructor(
    private toaster: ToasterService,
    private spinner: SpinnerService
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    this.spinner.start("Отримую дані...");

    if (environment.mock) {
      const { url, method } = request;
      if (url.match(/\/login$/)) {
        return this.returnResult(login);
      }
      if (url.match(/\/resourses$/)) {
        return this.returnResult(resourses);
      }
      if (url.match(/\/search-results$/)) {
        return this.returnResult(searchResults);
      }
      if (url.match(/\/questionnaires$/)) {
        return this.returnResult(questionnaires);
      }
      if (url.match(/\/test-id-0$/)) {
        return this.returnResult(searchResult0);
      }
      if (url.match(/\/test-id-1$/)) {
        return this.returnResult(searchResult1);
      }
      if (url.match(/\/test-id-2$/)) {
        return this.returnResult(searchResult2);
      }
      if (url.match(/\/test-id-0-0$/)) {
        return this.returnResult(questionnaires0);
      }
      if (url.match(/\/test-id-0-1$/)) {
        return this.returnResult(questionnaires1);
      }
      if (url.match(/\/test-id-0-2$/)) {
        return this.returnResult(questionnaires2);
      }
    }

    return next.handle(request).pipe(
      finalize(() => {
        this.spinner.stop();
      }),
      catchError(errResponse => {
        const { error } = errResponse;
        this.toaster.open(error?.message || errResponse.message || "Unknown HTTP Error");
        return throwError(() => errResponse);
      })
    );
  }

  private returnResult(body: any): Observable<HttpResponse<any>> {
    return of(new HttpResponse({ status: 200, body }))
      .pipe(
        delay(1000),
        finalize(() => {
          this.spinner.stop();
        })
      );
  }
}
